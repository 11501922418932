.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 20px;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    text-decoration: none;
    color: #fff;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #666;
  }
  