.header {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navbar li {
    margin-right: 20px;
  }
  
  .navbar a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar ul {
      margin-top: 10px;
    }
  
    .navbar li {
      margin: 0;
      margin-bottom: 10px;
    }
  }

.vlogo {
    height: 20vh;
    pointer-events: none;
  }

